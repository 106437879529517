$phone: '(max-width: 480px)';
$tablet-portrait: '(max-width: 767px)';
$tablet-landscape: '(min-width: 768px) and (max-width: 979px)';
$large-desktop: '(min-width: 1200px)';
$retina: '(-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-device-pixel-ratio: 2), (min-resolution: 192dpi),(min-resolution: 2dppx)';

@mixin respond-to($val, $query) {
  @media ($val: $query) {
    @content;
  }
}

@mixin respond-to-portrait() {
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin respond-to-landscape() {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin imgRetina(
  $image,
  $extension,
  $width,
  $height,
  $position: center,
  $repeat: no-repeat
) {
  --background: none;
  background: url($image+'.'+$extension) $repeat $position !important;

  @media screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (min--moz-device-pixel-ratio: 2),
    screen and (-moz-min-device-pixel-ratio: 2),
    screen and (-o-min-device-pixel-ratio: 2/1),
    screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background: url($image+'@2x'+'.'+$extension) $repeat $position !important;
    background-size: $width $height !important;
  }
}
