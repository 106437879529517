@import '../../theme/logo';

.page-company {

  ion-content.content-section {
    // 讓背景顏色有點灰
    --background: #eee !important;
  }

  .logo.immutable {
    // Notice: 這是為了修正 bug：圖片的大小跟旁邊的標題字不同
    font-size: inherit;
  }

  .section-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      padding: 20px;
    }

    .team:hover {
      cursor: pointer;
    }

    .team {
      // 團隊卡片的樣式

      text-align: center;
      img {
        // 垂直與水平置中
        margin: auto;
      }
    }

  }
}