.page-photo-viewer-modal {
  $top-height: 56px;
  $font-border-shadow: var(--ion-color-medium);

  .swiper-zoom-container {
    min-height: calc(100vh - #{$top-height});
  }

  ion-content {
    --background: rgba(var(--ion-color-dark-rgb), 1);
  }

  .next {
    --background: rgba(var(--ion-color-dark-rgb), 0.42);
  }

  .prev {
    --background: rgba(var(--ion-color-dark-rgb), 0.42);
  }

  ion-badge.count {
    position: absolute;
    height: auto;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 22px;
    line-height: 24px;
    font-family: monospace;
    font-weight: bold;
  }

  ion-chip.memo {
    position: absolute;
    height: auto;
    top: 0;
    background: rgba(var(--ion-color-dark-rgb), 0.8);
    font-weight: bold;
    text-shadow: -1px 0 $font-border-shadow, 0 1px $font-border-shadow,
      1px 0 $font-border-shadow, 0 -1px $font-border-shadow;

    white-space: pre-wrap;
  }

  ion-slide {
    display: flex;
    flex-wrap: wrap;
  }

  .filename,
  .user {
    color: var(--ion-color-light);
    font-size: 12px;
    font-weight: bold;
    text-shadow: -1px 0 $font-border-shadow, 0 1px $font-border-shadow,
      1px 0 $font-border-shadow, 0 -1px $font-border-shadow;
  }
}

@media only screen and (min-height: 0px) and (min-width: 0px) {
  .modal-fullscreen {
    .modal-wrapper {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
    }
  }
}
