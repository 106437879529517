@import '../../theme/logo';

$rwdWidthDiff: 0.5%;

.page-checklist {
  ion-content, .qrcode-view .table-name {
    // 讓背景顏色有點灰
    --background: #eee !important;
  }

  ion-button.logo.immutatble {
    // workaround: 讓 logo 圖片和文字(text: 建築履歷)能對齊
    font-size: 12.5px;
  }

  ion-content ion-toolbar {
    --background: translucent;
  }

  .legend {
    white-space: pre-wrap;
  }

  .team-tag-list {
    // 團隊標籤列表區的樣式
    width: 50%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    ion-button.tag {
      // 每個標籤的樣式
      margin: 0.5rem;
    }
  }

  .list-of-checklist {
    // 檢核表格的列表區
    width: 40%;
    margin: 10px auto 18px auto;

    ion-select {
      // 選擇 檢核表格 的下拉選單
      border-radius: 10px;
      box-shadow: 2px 1px 15px 8px rgba(0, 0, 0, 0.2);
    }

  }

  .section-custom-fields {
    // 表頭欄位區(客製化欄位) 的樣式

    margin: 10px auto; // 與上下內容(表格欄位)能有更多間隔

    .header-of-custom-fields {
      // 表頭欄位區的標題文字內縮
      padding-left: 27px;
    }

    ion-label.evaluated {
      font-weight: bold;
      color: var(--ion-color-primary);
    }
  }

  ion-list {
    padding: 0;
    ion-item {

      ion-label {
        font-size: 15px !important;
        margin: 5px !important;
        
        &:first-child {
          // 去除掉 ionic 加在 ion-label 的 flex 樣式，讓第二個以後(含第二個) ion-label 依照剩餘空間調整

          flex: unset;
        }
      }
    }
  }

  ion-card {
    box-shadow: none !important;
    border: 1px solid rgba(var(--ion-color-light-rgb), 0.2);

    ion-card-header {
      padding: 5px 5px 7px 5px;
    }

    ion-item {
      font-size: 15px !important;
    }

    ion-card-content {
      border: 1px solid rgba(var(--ion-color-light-rgb), 0.2);
    }
  }

  ion-card-title {
    font-size: 15px;
  }

  ion-card-title ion-chip {
    font-size: 15px !important;
    vertical-align: baseline;
    padding: 5px;
    margin: 0;
  }

  ion-card.success {
    border: 1px solid rgba(var(--ion-color-success-rgb), 0.3);
    background: rgba(var(--ion-color-success-rgb), 0.1);
    ion-chip {
      background: var(--ion-background-color);
    }
  }

  ion-card.danger {
    border: 2px dashed rgba(var(--ion-color-danger-rgb), 0.3);
    background: rgba(var(--ion-color-danger-rgb), 0.1);
    ion-chip {
      background: var(--ion-background-color);
    }
    ion-label.danger {
      color: rgba(var(--ion-color-danger-rgb), 1);
    }
  }

  ion-card.primary {
    border: 1px solid rgba(var(--ion-color-success-rgb), 0.3);
    background: rgba(var(--ion-color-success-rgb), 0.1);
    ion-chip {
      color: var(--ion-color-success-shade);
      background: var(--ion-background-color);
    }
  }

  ion-card.warning {
    ion-chip {
      background: var(--ion-background-color);
    }
  }

  ion-card.light {
    border: 2px dashed rgba(var(--ion-color-medium-rgb), 0.3);
    ion-card-header {
      background: rgba(var(--ion-color-light-rgb), 0.7);
    }
    ion-chip {
      display: none;
      background: var(--ion-background-color);
    }
  }

  .photo-slider {
    margin: 0 auto;
    width: 100%;
    background: black;
  }

  .carousel .slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel .slide img {
    max-height: 240px;
    width: auto;
  }

  .carousel .slide .legend {
    bottom: 0;
  }

  .suiqui-normal-rwd-styling {
    // Notice: 僅限此頁的 suiqui RWD 寬度樣式

    @media screen and (max-width: 768px) {
      width: 70%;
    }
    @media screen and (max-width: 576px) {
      width: 95%;
    }
  }

}


.options-of-checklist .popover-content {
  // 點選檢核表格的下拉選單時，顯示的 popover 視窗畫面樣式
  width: 40% - $rwdWidthDiff;

  ion-radio[aria-disabled="true"] {
    // 若 ion-radio 標籤被標記成是 disable，則設定它不出現
    display: none;
  }

  ion-item.select-interface-option:not(.item-interactive-disabled)::part(native) {
    // 因為使用需求，所以調整下拉選單 popover 時的樣式。Notice: 因為是 shadow-root 所以使用 part()
    display: flex;
    flex-direction: row-reverse;
  }

  ion-item.select-interface-option:not(.item-interactive-disabled) ion-label {
    // 調整下拉選單 popover 的每個可選擇項目的文字樣式，需要與左邊 radio 有間隔
    margin-left: .5rem;
  }

  @media screen and (max-width: 768px) {
    width: 70% - $rwdWidthDiff;
  }

  @media screen and (max-width: 576px) {
    width: 95% - $rwdWidthDiff;
  }
}
