@import '../theme/logo';

.page-checklist {
  ion-content ion-toolbar {
    --background: translucent;
  }

  .legend {
    white-space: pre-wrap;
  }

  ion-list {
    padding: 0;
    ion-item {
      ion-label {
        font-size: 15px !important;
        margin: 5px !important;

        &:first-child {
          // 去除掉 ionic 加在 ion-label 的 flex 樣式，讓第二個以後(含第二個) ion-label 依照剩餘空間調整

          flex: unset;
        }

        &.evaluated {
          font-weight: bold;
          color: var(--ion-color-primary);
        }
      }
    }
  }

  ion-card {
    box-shadow: none !important;
    border: 1px solid rgba(var(--ion-color-light-rgb), 0.2);

    ion-card-header {
      padding: 5px 5px 7px 5px;
    }

    ion-item {
      font-size: 15px !important;
    }

    ion-card-content {
      border: 1px solid rgba(var(--ion-color-light-rgb), 0.2);
    }
  }

  ion-card-title {
    font-size: 15px;
  }

  ion-card-title ion-chip {
    font-size: 15px !important;
    vertical-align: baseline;
    padding: 5px;
    margin: 0;
  }

  ion-card.success {
    border: 1px solid rgba(var(--ion-color-success-rgb), 0.3);
    background: rgba(var(--ion-color-success-rgb), 0.1);
    ion-chip {
      background: var(--ion-background-color);
    }
  }

  ion-card.danger {
    border: 2px dashed rgba(var(--ion-color-danger-rgb), 0.3);
    background: rgba(var(--ion-color-danger-rgb), 0.1);
    ion-chip {
      background: var(--ion-background-color);
    }
    ion-label.danger {
      color: rgba(var(--ion-color-danger-rgb), 1);
    }
  }

  ion-card.primary {
    border: 1px solid rgba(var(--ion-color-success-rgb), 0.3);
    background: rgba(var(--ion-color-success-rgb), 0.1);
    ion-chip {
      color: var(--ion-color-success-shade);
      background: var(--ion-background-color);
    }
  }

  ion-card.warning {
    ion-chip {
      background: var(--ion-background-color);
    }
  }

  ion-card.light {
    border: 2px dashed rgba(var(--ion-color-medium-rgb), 0.3);
    ion-card-header {
      background: rgba(var(--ion-color-light-rgb), 0.7);
    }
    ion-chip {
      display: none;
      background: var(--ion-background-color);
    }
  }

  .photo-slider {
    margin: 0 auto;
    width: 100%;
    background: black;
  }

  .carousel .slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel .slide img {
    max-height: 240px;
    width: auto;
  }

  .carousel .slide .legend {
    bottom: 0;
  }
}
