.page-home {
  ion-content ion-toolbar {
    --background: translucent;
  }
}

.title-md {
  font-size: 18px !important;
}

:root {
  button.cancel {
    color: var(--ion-color-medium);
  }
  button.ok {
    color: var(--ion-color-primary);
    font-weight: bold;
  }
}

ion-split-pane {
  --border: none !important;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.update-available {
  --background: var(--ion-color-primary-shade) !important;
}
