@import '../theme/utils/mixin';

$logo: '../../assets/img/logo-resume-long';
$logo-welcome: '../../assets/img/logo-welcome';

.logo-welcome {
  height: 100px;
  @include imgRetina($logo-welcome, 'png', 150px, 28px, no-repeat, center);
}

ion-button.logo {
  --box-shadow: none;
  vertical-align: middle;
  --background: transparent !important;
  @include imgRetina($logo, 'svg', 95px, 18px, no-repeat, center);
  color: transparent !important;
  &:hover {
    color: transparent !important;
  }

  ~ span {
    vertical-align: middle;
  }
}

.immutable {
  cursor: default !important;
  pointer-events: none;

  &:hover {
    color: default !important;
  }
}

.openable {
  cursor: pointer !important;
}

.logo ~ .subtitle {
  font-size: 15px !important;
  cursor: default !important;
  pointer-events: none;
  margin-top: -18px;
  margin-left: -20px;
  font-weight: bold;
}
